<script>
import AuthMixin from '@/mixins/auth'
import MixinUser from '@/mixins/user'
import { intercomPaymentUserBoot } from '@/helpers/intercomHelpers'
import { paymentQueryHelper } from '@/helpers/paymentQueryHelper'

export default {
  name: 'AuthenticationServiceProvider',

  mixins: [AuthMixin, MixinUser],

  data () {
    return {
      loaders: {
        login: false,
        signUp: false,
        socialSignup: false,
        socialLogin: false,
        forgetPassword: false,
        invite: false
      },
      forms: {
        signUp: {
          email: '',
          password: ''
        },
        login: {
          email: '',
          password: ''
        },
        forgetPassword: {
          email: ''
        },
        invite: {
          firstName: '',
          lastName: '',
          password: '',
          repeatPassword: '',
          token: '',
          email: ''
        }
      },
      errorMessage: '',
      showSuccessAlertMailSent: false,
      passwordResetRequested: false
    }
  },

  computed: {
    isLifetimeDeal () {
      const routeName = this.$route.name

      return routeName === 'payment-lifetime-deal' || routeName === 'payment-lifetime-deal-login'
    },

    loginRoute () {
      return this.isLifetimeDeal ? 'payment-lifetime-deal-login' : 'payment-login'
    },

    signUpRoute () {
      return this.isLifetimeDeal ? 'payment-lifetime-deal' : 'payment'
    },

    paymentQueryParams () {
      return paymentQueryHelper(this.isLifetimeDeal, this.$route.query)
    },

    redirectRouteObject () {
      return this.isLifetimeDeal
        ? { name: 'payment-lifetime-deal', query: this.paymentQueryParams }
        : { name: 'payment', query: this.paymentQueryParams }
    }
  },

  watch: {
    '$route.fullPath': {
      handler () {
        this.errorMessage = ''
      },
      immediate: true
    }
  },

  methods: {
    login () {
      this.errorMessage = ''
      this.loaders.login = true

      this.$http.post('authentication/login', this.forms.login)
        .then((response) => {
          this.loaders.login = false

          if (response.status === 200) {
            const { token, user } = response.data.payload
            this.setupCookie(token)

            // set Authorization token
            this.$http.defaults.headers.common['X-Authorization'] = `Bearer ${token}`
            this.$store.commit('user/setUser', { user })
            intercomPaymentUserBoot(user, this.$intercom)

            if (user.selectedIdea) {
              this.$store.commit('idea/setIdea', { idea: user.selectedIdea })
            }
            if (user.ideas) {
              this.$store.commit('ideaCollection/setIdeas', { ideas: user.ideas })
            }
            if (user.sharedIdeas) {
              this.$store.commit('ideaCollection/setSharedIdeas', user.sharedIdeas, { root: true })
            }

            this.$emit('login-success', user)
          }
        })
        .catch(e => {
          this.loaders.login = false

          if (typeof e.response !== 'undefined' && e.response.status === 404) {
            this.errorMessage = this.$t('validations.incorrectEmailOrPassword')

            return
          }

          if (typeof e.response !== 'undefined' && e.response.status === 403) {
            this.errorMessage = this.$t('validations.accountHasBeenCancelled')

            return
          }

          this.errorMessage = this.$t('validations.sorrySomethingWentWrong')
        })
    },

    signUp () {
      this.errorMessage = ''
      this.loaders.signUp = true

      return this.$http.post('authentication/payment-signup', this.forms.signUp)
        .then((response) => {
          this.loaders.signUp = false
          if (response.status === 201) {
            const { token, user } = response.data.payload
            this.setupCookie(token)

            this.$gtm.trackEvent({
              event: 'SignupSuccess-paymentSignup'
            })

            intercomPaymentUserBoot(user, this.$intercom)
            this.$http.defaults.headers.common['X-Authorization'] = `Bearer ${token}`
            this.$store.commit('user/setUser', { user: user })

            this.$emit('signup-success', user)
          }
        })
        .catch(e => {
          this.loaders.signUp = false

          if (typeof e.response !== 'undefined' && e.response.status === 409) {
            this.$gtm.trackEvent({
              event: 'UserAccountExist-paymentSignup'
            })
            this.errorMessage = this.$t('validations.accountAlreadyExists')

            return
          }

          if (e.response.status === 403) {
            this.errorMessage = this.$t('validations.accountHasBeenCancelled')

            return
          }

          this.errorMessage = this.$t('validations.sorrySomethingWentWrong')
        })
    },

    loginSocial (provider) {
      this.loaders.socialLogin = true
      this.$auth.options.providers[provider].url = `/api/v1/authentication/login/${provider}`

      this.$auth.authenticate(provider)
        .then(async response => {
          const { token, user } = response.data.payload
          this.setupCookie(token)
          intercomPaymentUserBoot(user, this.$intercom)

          this.$store.commit('user/setUser', { user })
          if (response.data.payload.user.selectedIdea) {
            this.$store.commit('idea/setIdea', { idea: user.selectedIdea })
          }

          this.$emit('login-social-success', user)
        })
        .catch(e => {
          this.loaders.socialLogin = false

          if (typeof e.response !== 'undefined' && e.response.status === 404) {
            this.errorMessage = this.$t('validations.accountDoesNotMatch')

            return
          }

          if (typeof e.message !== 'undefined' && e.message === this.$t('validations.authPopupClosed')) {
            return
          }

          this.errorMessage = this.$t('validations.sorrySomethingWentWrong')
        })

      return true
    },

    signupSocial (provider) {
      this.loaders.socialSignup = true
      this.$auth.options.providers[provider].url = `/api/v1/authentication/payment-signup/${provider}`

      return this.$auth.authenticate(provider)
        .then(response => {
          const { token, user } = response.data.payload
          this.setupCookie(token)
          this.$http.defaults.headers.common['X-Authorization'] = `Bearer ${token}`
          intercomPaymentUserBoot(user, this.$intercom)

          this.$store.commit('user/setUser', { user: user })
          this.$emit('signup-social-success')
        })
        .catch(e => {
          this.loaders.socialSignup = false

          if (typeof e.response !== 'undefined' && e.response.status === 409) {
            this.errorMessage = this.$t('validations.accountAlreadyExists')

            return
          }

          if (typeof e.message !== 'undefined' && e.message === this.$t('validations.authPopupClosed')) {
            return
          }

          this.errorMessage = this.$t('validations.sorrySomethingWentWrong')
        })
    },

    forgetPassword () {
      this.errorMessage = ''
      this.loaders.forgetPassword = true

      return this.$http.post('authentication/forgot-password', this.forms.forgetPassword).then((response) => {
        if (response.status === 200) {
          this.showSuccessAlertMailSent = true
          this.passwordResetRequested = true
          this.loaders.forgetPassword = false
        }
      })
        .catch(e => {
          this.loaders.forgetPassword = false
          if (typeof e.response !== 'undefined' && e.response.status === 401) {
            this.showSuccessAlertMailSent = true
            this.passwordResetRequested = true

            return
          }

          this.errorMessage = this.$t('validations.sorrySomethingWentWrong')
        })
    },

    invitedAuthentication () {
      this.errorMessage = ''
      this.loaders.invite = true

      return this.$http.post('authentication/signup/invited', this.forms.invite)
        .then((response) => {
          if (response.status === 201) {
            const { token, user } = response.data.payload
            this.setupCookie(token)

            // set Authorization token
            this.$http.defaults.headers.common['X-Authorization'] = `Bearer ${token}`
            this.$store.commit('user/setUser', { user: user })
            if (user.selectedIdea) {
              this.$store.commit('idea/setIdea', { idea: user.selectedIdea })
            }
            if (user.ideas) {
              this.$store.commit('ideaCollection/setIdeas', { ideas: user.ideas })
            }
            if (user.sharedIdeas) {
              this.$store.commit('ideaCollection/setSharedIdeas', user.sharedIdeas, { root: true })
            }
            intercomPaymentUserBoot(user, this.$intercom)
            this.$router.push({ name: 'idea-collection', hash: '#shared' })
          }
        })
        .catch(e => {
          this.loaders.invite = false

          if (typeof e.response !== 'undefined' && e.response.status === 401) {
            this.errorMessage = this.$t('validations.invalidOrExpiredToken')

            return
          }

          if (typeof e.response !== 'undefined' && e.response.status === 403) {
            this.errorMessage = e.response.data.message

            return
          }

          this.errorMessage = this.$t('validations.sorrySomethingWentWrong')
        })
    },

    setInvitationData () {
      this.forms.invite.token = this.$route.query.token
      this.forms.invite.email = this.$route.query.email
    },

    goToLogin () {
      this.$cookies.remove('access_token')
      localStorage.removeItem('persisted_state')
      this.$router.push({ name: this.loginRoute, query: this.paymentQueryParams })
    },

    goToSignUp () {
      this.$cookies.remove('access_token')
      localStorage.removeItem('persisted_state')
      this.$router.push({ name: this.signUpRoute, query: this.paymentQueryParams })
    },

    onMounted () {
      this.$cookies.remove('access_token')
      localStorage.removeItem('persisted_state')
      this.$store.dispatch('resetState')
    },

    redirectAfterLogin (user) {
      this.$router.push(
        user.isAppSumoUser
          ? { name: 'payment-lifetime-deal', query: this.isLifetimeDeal ? this.paymentQueryParams : {} }
          : this.redirectRouteObject
      )
    },

    getNextComponent () {
      return {
        component: this.isLifetimeDeal ? 'SelectLifetimePlan' : 'SelectPlan',
        isLifetimeDeal: this.isLifetimeDeal
      }
    }
  },

  render () {
    return this.$scopedSlots.default({
      // data
      loaders: this.loaders,
      forms: this.forms,
      errorMessage: this.errorMessage,
      showSuccessAlertMailSent: this.showSuccessAlertMailSent,
      passwordResetRequested: this.passwordResetRequested,

      // methods
      login: this.login,
      loginSocial: this.loginSocial,
      signUp: this.signUp,
      signupSocial: this.signupSocial,
      forgetPassword: this.forgetPassword,
      invitedAuthentication: this.invitedAuthentication,
      goToLogin: this.goToLogin,
      goToSignUp: this.goToSignUp
    })
  }
}
</script>
